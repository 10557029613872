import React, { useEffect, useState } from "react";

import { MONACO_EDITOR_CLASSNAME } from "../components/cell/monaco/constants";

import { HotKeys } from "./hotkeys.js";

export const MOUSETRAP_ALLOW_LIST_DELIMITER = "\n";

/**
 * KeyboardEvent.key
 * https://www.w3.org/TR/uievents-key/#named-key-attribute-values
 *
 * Note: this is NOT KeyboardEvent.code or KeyboardEvent.keyCode
 */
export enum Keys {
  ALT = "Alt",
  CONTROL = "Control",
  META = "Meta",
  SHIFT = "Shift",
  ENTER = "Enter",
  TAB = "Tab",
  ARROW_DOWN = "ArrowDown",
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
  ARROW_UP = "ArrowUp",
  BACKSPACE = "Backspace",
  DELETE = "Delete",
  ESCAPE = "Escape",
  SPACE = " ",
  D = "d",
  E = "e",
  G = "g",
  M = "m",
  Z = "z",
}

/**
 * KeyboardEvent.code
 *
 * Note - we are adding this since this should be consistent across operating
 * systems when determing if this key was pressed with ALT.
 *
 * https://www.w3.org/TR/uievents/#dom-keyboardevent-code
 */
export enum KeyBoardEventCode {
  A = "KeyA",
}

/**
 * Windows keyboard systems will treat alt + key events slightly differently -
 * checking the value of the "code" + "altKey" should work for both platforms.
 */
export const isAltKeyPress = (
  e: React.KeyboardEvent<Element>,
  code: KeyBoardEventCode,
): boolean => {
  return e.code === code && e.altKey;
};

export const DeleteKeys: string[] = [Keys.BACKSPACE, Keys.DELETE];

/**
 * Determine if the current platform is Mac.
 *
 * This uses the deprecated navigator.platform in order to be consistent with
 * Blueprint and mousetraps's platform detection.
 * https://github.com/palantir/blueprint/blob/31ce2f0a75ad5776b7bd7ed26c1dd4e1a4611eb3/packages/core/src/components/hotkeys/hotkeyParser.ts#L300-L305
 * https://github.com/ccampbell/mousetrap/blob/2f9a476ba6158ba69763e4fcf914966cc72ef433/mousetrap.js#L135
 *
 * Not exported since we _probably_ shouldn't rely on this function anywhere else.
 * Instead, you should most likely consume `ModKey` or `ModKeyChar` directly.
 */
function isMac(): boolean {
  const platform =
    typeof navigator !== "undefined" ? navigator.platform : undefined;
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  return platform === undefined ? false : /Mac|iPod|iPhone|iPad/.test(platform);
}

const isMacVal = isMac();

export const ModKey = isMacVal ? Keys.META : Keys.CONTROL;
export const ModKeyChar = isMacVal ? "⌘" : "ctrl";

// NB - for keyup events, the metaKey and ctrlKey properties are not set, so we need
// to explicitly check the key
export const evtModKey = (
  evt: React.MouseEvent | MouseEvent | React.KeyboardEvent | KeyboardEvent,
): boolean =>
  isMacVal
    ? evt.metaKey ||
      (evt.type === "keyup" && (evt as unknown as KeyboardEvent).key === "Meta")
    : evt.ctrlKey ||
      (evt.type === "keyup" &&
        (evt as unknown as KeyboardEvent).key === "Control");

/**
 * callback should be stable or memoized
 */
export function useOnHoldingKey(
  key: Keys | Keys[],
  callback: (holding: boolean) => void,
): void {
  useEffect(() => {
    const keys: string[] = Array.isArray(key) ? key : [key];

    const keyDown = (evt: KeyboardEvent): void => {
      if (keys.includes(evt.key)) {
        callback(true);
      }
    };
    const keyUp = (evt: KeyboardEvent): void => {
      if (keys.includes(evt.key)) {
        callback(false);
      }
    };
    document.addEventListener("keydown", keyDown);
    document.addEventListener("keyup", keyUp);
    return () => {
      document.removeEventListener("keydown", keyDown);
      document.removeEventListener("keyup", keyUp);
    };
  }, [key, callback]);
}

export function useIsHoldingKey(key: Keys | Keys[]): boolean {
  const [holdingKey, setHoldingKey] = useState(false);

  useOnHoldingKey(key, setHoldingKey);

  return holdingKey;
}

// TODO(GRVTY-892): Would be nice if we didn't block _all_ shortcuts for text inputs
const htmlTagsToIgnore = ["input", "select", "textarea"];

/**
 * Used by Mousetrap for determining if the given
 * keyboard shortcut input should be ignored.
 *
 * @param evt
 */
export function shouldIgnoreShortcut(
  evt: KeyboardEvent,
  _: Element,
  combo: string,
): boolean {
  if (
    evt?.target != null &&
    (evt.target as { tagName?: string }).tagName != null
  ) {
    const target = evt.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();

    // Some components may want to only allow a subset of combos through, so we
    // examine the provided allow list to confirm availability of the command.
    const mouseTrapAllowlist =
      // Note that the use of `#` as a delimiter here means we can't define a shortcut
      // using `#` as a key.
      target
        .getAttribute("data-mouse-trap-allowlist")
        ?.split(MOUSETRAP_ALLOW_LIST_DELIMITER) ?? [];
    if (mouseTrapAllowlist.length) {
      return !mouseTrapAllowlist.includes(combo);
    }

    // allow components to opt-out of mousetrap
    if (target.classList.contains("disable-mousetrap")) return true;

    // we want to always handle keyboard shortcuts if they originate from monaco
    const isMonaco =
      target.parentElement?.parentElement?.className.includes(
        MONACO_EDITOR_CLASSNAME,
      ) ?? false;

    const isSlate = Boolean(target.getAttribute("data-slate-editor"));
    const isProjectFind = HotKeys.OPEN_PROJECT_SEARCH === combo;

    // we also want to ignore short cuts if a user is trying
    // to use the keyboard to 'click' a button
    const isButtonClick = tagName === "button" && evt.key === Keys.ENTER;

    return (
      (htmlTagsToIgnore.includes(tagName) ||
        target.isContentEditable ||
        isButtonClick) &&
      !isMonaco &&
      !isSlate &&
      !isProjectFind
    );
  } else {
    return false;
  }
}
